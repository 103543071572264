import React from 'react'
import "./index.css"
import StoreLocationsMap from '../RestuarantLocator/StoreLocationsMap'
import i18next from 'i18next'
import JoinNewsLetter from '../Footer/JoinNewsLetter'

function OurLocation() {
  return (
    <div className='location-map-store'>
      <h2 className='location-heading'>
        {i18next.t("landingPage.stores_location")}
      </h2>
      <StoreLocationsMap></StoreLocationsMap>
      <div className='input-search-location'>
       
     <JoinNewsLetter></JoinNewsLetter>

      </div>
    </div>
  )
}

export default OurLocation
