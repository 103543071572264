import _ from "lodash";
import React from "react";
import { Helmet } from 'react-helmet';
import { Component } from "react";
import PhoneInput from "react-phone-input-2";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { trackOrder } from "../../Redux/Actions";
import i18next from "i18next";
import './track.css';
import { Modal } from "react-responsive-modal";
import { Button, Modal as BsModal } from 'react-bootstrap';
import queryString from 'query-string'
import jwtDecode from "jwt-decode";
import { toast } from "react-toastify";
import PickUpMap from "../Landing/pickupMap";
import "react-toastify/dist/ReactToastify.css";
toast.configure()
class index extends Component<{ storeData: any; trackOrder: (data: any) => {},location:any }, { order_id: any, phone: any, email_address: any, isValidEmail: any, [x: number]: any;openModal:any, origin:any, locationEnabled:any,use_pickup_map:any,directions:any,openGetDirModal:any }> {

    constructor(props: any) {
        super(props);
        this.state = {
            order_id: "",
            email_address: "",
            phone: "",
            openModal:false,
            isValidEmail: true,
            origin:null,
            locationEnabled:false,
            use_pickup_map:false,
            directions:null,
            openGetDirModal:false
        };
        // this.handleSubmit = this.handleSubmit.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.isOrderReady = this.isOrderReady.bind(this);
        this.validateEmail = this.validateEmail.bind(this);
    }
    componentDidMount() {
        if ("geolocation" in navigator) {
            navigator.geolocation.getCurrentPosition((position: any) => {
                this.setState({origin:{lat:position.coords.latitude,lng:position.coords.longitude}})
            })
        }
        let phone = localStorage.getItem("phoneNumber")
        let order_id = localStorage.getItem("orderId")
        if (order_id) {
            const data: any = {
                order_id: order_id,
                phone_number: phone
            };
            this.props.trackOrder(data);
            let params:any = queryString.parse(this.props.location.search)
            const paramstoken=params && params.token;
            if(paramstoken) {
                let decoded:any = jwtDecode(paramstoken)
                if(decoded.order_status == 'Success') {
                    this.setState({openModal:true})
                }
            }
            this.setState({ order_id: order_id, phone: phone })
        }
        window.scrollTo(0, 0);
        const script = document.createElement("script");

    script.src = "https://code.iconify.design/2/2.2.1/iconify.min.js";
    script.async = true;

    document.body.appendChild(script);
    }

    isNumeric(str: Number) {
        if (typeof str != "string") return false 
        return !isNaN(str)
    }

    handleInputChange(event: { target: { name: any; value: any } }) {
        const orderID = (event.target.value)
        let validator = orderID.match(/^-?\d*$/)
        if(validator == null) {
            return
        }
        if(this.isNumeric(orderID)) {
            if (orderID.length <= 6) {            
                this.setState({ [event.target.name]: event.target.value });
            }
        }
    }
    validateEmail(event: { target: { name: any; value: any; }; }) {
        let reg = /^\w+([\.-]?\w+)@\w+([\.-]?\w+)(\.\w{2,4})+$/;
        if (reg.test(event.target.value) === false) {
            this.setState({ email_address: event.target.value, isValidEmail: false })
        } else {
            this.setState({ email_address: event.target.value, isValidEmail: true });
        }
    };
    isOrderReady() {
        let { order_id, phone } = this.state;
        return (order_id !== "" && phone !== '' && (phone.length >= 10 || phone.length <= 15));
    }
    handleSubmit = (event: any) => {
        const { order_id, phone } = this.state;
        let str_phone_number = phone
        // if (str_phone_number.charAt(0) === '9') {
        //     str_phone_number = str_phone_number.substring(2);
        //     str_phone_number = "0" + str_phone_number
        // }
        const data: any = {
            order_id: order_id,
            phone_number: str_phone_number
        };
        this.props.trackOrder(data);
    };
    handleOnChange = (value: any, data: any) => {
        this.setState({ phone: value });
    };
    getDirections = async (origin:any,destination:any)=>{
        this.setState({openGetDirModal:true})
        navigator.geolocation.getCurrentPosition(position => {
            this.setState({locationEnabled: true})
            // console.log(position)
          }, error => {
              console.error(error)
              this.setState({use_pickup_map:false,locationEnabled: false})
              return
          })
        // console.log("response pickup",origin,destination);
        const directionsService = new google.maps.DirectionsService();
        const results = await directionsService.route({
        origin:origin,
        destination:destination,
        // origin: {lat:31.4446276,lng:74.2830205},
        // destination: {lat:25.1187856,lng:55.2034753},
        travelMode: google.maps.TravelMode.DRIVING,
        })
        this.setState({directions:results,use_pickup_map:true})
        // console.log("response directions",results)
      }
    render() {
        let { storeData } = this.props;
        let description = ''
        if(storeData.order_status_description == 'pending') {
            description = 'Accepted'
        } else if(storeData.order_status_description == 'in kitchen') {
            description = 'Order being prepared'
        } else if(storeData.order_status_description == 'dispatched') {
            description = 'Rider on the way'
        } else if(storeData.order_status_description == 'ready to dispatch') {
            description = 'Ready to Pickup'
        } else if(storeData.order_status_description == 'delivered') {
            description = 'Delivered'    
        } else {
            description = storeData.order_status_description
        }
        let canonicalUrl = window.location.href
        const lang = localStorage.getItem('lang') || 'en';
        return (
            <main className="main">
                <Helmet>
                    <link rel="canonical" href={canonicalUrl} />
                </Helmet>
                <nav aria-label="breadcrumb" className="breadcrumb-nav">
                    <div style={{position:"relative",zIndex:2}} className="container">
                    <div className="backBtn-wrapper mobile-view-page">
                        <Link to={`/menu`}>
                            <div className="org-header__btn--circle">
                                {/* <svg data-v-c2f068f0="" fill="#820c24" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" data-spec="icon-arrow-left" className="org-header__icon" style={{ width: '15px', height: '15px' }}><path d="M74.363 79.637a8.997 8.997 0 010 12.727C72.605 94.121 70.303 95 68 95s-4.605-.879-6.363-2.637l-36-36a8.997 8.997 0 010-12.727l36-36a8.997 8.997 0 0112.727 0 8.997 8.997 0 010 12.727L44.727 50l29.636 29.637z"></path></svg> */}
                                <span className="iconify"
                    style={{
                      // marginRight: "10px",
                      width: "45px",
                      height: "45px",
                      color: "#179145"
                    }}
                    data-icon="bi:arrow-left-circle">
                  </span>
                            </div>
                        </Link>
                    </div>
                    </div>
                </nav>
                <div className="container mt-3">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="panel panel-default">
                                <div className="panel-body">
                                    <div id="content">
                                        <h5 className="myprofile text-dark track-text-mobile" style={{fontSize:"23px"}} id="txt" >{i18next.t('trackOrderPage.track_order')}</h5>
                                        <p className="myprofile" style={{ fontSize: "18px", textAlign: 'center' }}>{i18next.t('trackOrderPage.track_order_description')}</p>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6 mb-3">
                                            <div className="row" >
                                                <div className="form-group col-md-12 mb-3">
                                                    <h5 className="myprofile text-dark" style={{fontSize:"16px"}} id="txt">{i18next.t('trackOrderPage.phone_number')}</h5>
                                                    <p></p>
                                                    <PhoneInput
                                                        inputProps={{
                                                            name: 'phone',
                                                            required: true,
                                                            autoFocus: true
                                                        }}
                                                        country={'ae'}
                                                        disableDropdown={true}
                                                        onlyCountries={['ae']}
                                                        countryCodeEditable={false}
                                                        // // masks={{pk: ' (...) ..-..-...',}}
                                                        // // prefix={'+'}
                                                        // placeholder="3..-......."
                                                        value={this.state.phone}
                                                        onChange={this.handleOnChange}
                                                    />
                                                </div>
                                                <div className="form-group col-md-12">
                                                    <h5 className="myprofile text-dark mt-0" style={{fontSize:"16px"}}  id="txt">{i18next.t('trackOrderPage.order_id')}</h5>
                                                    <input name="order_id" placeholder={lang=="en"?"Enter your order id":"أدخل رقم طلبك"} value={this.state.order_id} style={{ fontSize: "15px" }} type="text" className="form-control" id="round" required onChange={this.handleInputChange} />
                                                </div>

                                                {/* <div className="form-group col-md-5">
                                                <h5 id="txt">Billing email</h5>
                                                <input name="email_address" placeholder="Email you used during checkout" style={{ fontSize: "15px" }} type="text" className="form-control" id="round" onChange={this.validateEmail} />
                                                {(this.state.isValidEmail == false && this.state.email_address !== "") &&
                                                    <small className="form-text text-danger">Invalid Email</small>
                                                }
                                            </div> */}
                                                <div className="form-group col-md-12">
                                                    <button id="h" onClick={this.handleSubmit} disabled={!this.isOrderReady()} className="btn custom-default-red-button track-order">{i18next.t('trackOrderPage.track_btn')}</button>
                                                    {storeData.delivery_status=="Pickup" &&
                                                    <button onClick={() => this.getDirections(this.state.origin, { lat: Number(storeData.storeLat), lng: Number(storeData.storeLng) })} className="btn get_direction_btn_for_track mx-3">
                                                        {i18next.t("landingPage.get_directions")}
                                                    </button>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        {!_.isEmpty(storeData) &&
                                            <div className="col-md-6 mb-3">
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <h5 className="myprofile text-dark" id="txt">{i18next.t('trackOrderPage.track_btn')}</h5>
                                                    </div>
                                                    <div className="form-group col-md-6">
                                                        <h5 className="myprofile text-dark" id="txt">{i18next.t('trackOrderPage.order_id')}</h5>
                                                        <input name="order_id" disabled value={storeData.order_id} style={{ fontSize: "15px" }} className="form-control" id="round" required />
                                                    </div>
                                                    <div className="form-group col-md-6">
                                                        <h5 className="myprofile text-dark" id="txt">{i18next.t('trackOrderPage.order_total')} (AED)</h5>
                                                        <input name="order_id" disabled value={storeData.order_grossprice} style={{ fontSize: "15px" }} type="text" className="form-control" id="round" required />
                                                    </div>
                                                    <div className="form-group col-md-6">
                                                        <h5 className="myprofile text-dark" id="txt">{i18next.t('trackOrderPage.order_method')}</h5>
                                                        <input name="order_id" disabled value={storeData.delivery_status} style={{ fontSize: "15px" }} type="text" className="form-control" id="round" required />
                                                    </div>
                                                    <div className="form-group col-md-6">
                                                        <h5 className="myprofile text-dark" id="txt">{i18next.t('trackOrderPage.order_status')}</h5>
                                                        <input name="order_id" disabled value={description} style={{ fontSize: "15px", textTransform: 'capitalize' }} type="text" className="form-control" id="round" required />
                                                    </div>
                                                    <div className="form-group col-md-12">
                                                        <h5 className="myprofile text-dark" id="txt">{i18next.t('trackOrderPage.ordering_store')}</h5>
                                                        <input name="order_id" disabled value={storeData.store_name} style={{ fontSize: "15px" }} type="text" className="form-control" id="round" required />
                                                    </div>
                                                    {storeData.delivery_status == 'Delivery' &&
                                                        <div className="form-group col-md-12">
                                                            <h5 className="myprofile text-dark" id="txt">{i18next.t('trackOrderPage.delivery_address')}</h5>
                                                            <input name="order_id" disabled value={storeData.delivery_address} style={{ fontSize: "15px" }} type="text" className="form-control" id="round" required />
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal
                    open={this.state.openModal}
                    showCloseIcon={false}
                    onClose={()=>{this.setState({openModal:false})}}
                    center
                >
                    <div style={{ paddingTop: "5%", paddingBottom: "2%" }}>
                        <p>
                            {i18next.t('checkoutPage.congratulations')} {i18next.t('checkoutPage.order_placed')}
                        </p>

                        <div style={{ textAlign: "center" }}>
                        <button
                            onClick={()=>{this.setState({openModal:false})}}
                            className="btn btn-sm btn-primary center custom-default-red-button">
                            {i18next.t('common.ok')}
                        </button>
                        </div>
                    </div>
                </Modal>
                <BsModal show={this.state.openGetDirModal} onHide={() => { this.setState({ openGetDirModal: false }) }}>
                    <BsModal.Header style={{height:"unset",display:"flex",justifyContent: "center",padding:"unset"}}>
                        <BsModal.Title>
                           <span style={{position:"relative",top:"10px"}}>{lang=='ar' ? 'اتجاهات المتجر' : 'Store Directions'}</span>
                        </BsModal.Title>
                    </BsModal.Header>
                    <BsModal.Body style={{ overflowX: 'hidden' }}>
                        {this.state.use_pickup_map && <PickUpMap
                            location={this.state.origin}
                            directions={this.state.directions}
                        />}
                    </BsModal.Body>
                    <BsModal.Footer className="justify-content-center">
                        <Button onClick={() => { this.setState({ openGetDirModal: false }) }} className="btn cart-button close-button btn btn-primary">
                            {lang=='ar' ? 'أغلق' : 'Close'}
                        </Button>
                    </BsModal.Footer>
                </BsModal>
            </main>
        );
    }
}
const mapStateToProps = (state: any) => {
    return {
        storeData: state.login.storeData
    };
};
const mapDispatchToProps = (dispatch: any) => {
    return {
        trackOrder: function (data: any) {
            dispatch(trackOrder(data));
        },
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(index);