import React, { Component } from "react";
import NumericInput from "react-numeric-input";
import { Link } from "react-router-dom";
import { CartkProps, CartKState } from "../Interface/loginInterface";
import "./styleCart.css";
import Geocode from "react-geocode";
import Autocomplete from "react-google-autocomplete";
import { withGoogleMap, withScriptjs } from "react-google-maps";
import { favouriteAdd,getItemDetail } from "../../Redux";
import { toast } from 'react-toastify';
import i18next from "i18next";
import {
  findLocalStores,
  findPickupStores,
  getCart,
  saveCart,
  saveGuestAddress,
} from "../../Redux";
import { connect } from "react-redux";
import { Button, Modal as BsModal } from 'react-bootstrap';
import _ from "lodash";
import { handleCurtlery, handleSpecialInstruction, setTabName, TopItemsList } from "../../Redux/Actions";
import { BASE_URL, priceunit } from "../../ApiManager/client-config";
import InnerDetails from "../InnerDetail/innerDetailModal";
import UseTrackEvent from "../../hooks/useTrackHook";

Geocode.setApiKey("AIzaSyDGq7xWqvDvuvqwyTGJZDjfcRy1aCRmvt8");
class index extends Component<CartkProps, CartKState> {
  // discount: number;
  cartTotal: any;
  itemTotal: any;
  tax: any;
  public ScrollYLoc: any;
  public scrollDownFlag: any;
  constructor(props: any) {
    super(props);
    this.state = {
      arrows: false,
      value: "",
      addressCurrent: "",
      searchString: "",
      lat: "",
      lng: "",
      isOpen: false,
      windowWidth: window.innerWidth,
      itemSelected: {},
      storeOpenFlag: true,
      descBoxHeight: 0,
      isItemHaveModifier: [],
      addEvent:()=>{}
    };
    this.cartTotal = 0;
    this.ScrollYLoc = 0;
    this.scrollDownFlag = Boolean;
  }

  saveEvents = (option:any) => {
    try {
      this.state.addEvent({
        category: "events",
        events: option
      });
    } catch (error) {
        console.error("error while saving event on header page", error);
    }
  }

  handleSetAddEvent = (eventFunc:any) => {
    this.setState({ addEvent: eventFunc });
  }
  async componentDidMount() {
    window.scrollTo(0, 0)
    this.props.getCart();
    let orderType: any = await sessionStorage.getItem("orderType");
    if (!orderType) {
      this.props.history && this.props.history.push("/")
    }
    this.ScrollYLoc = window.scrollY;
    window.addEventListener('scroll', this.listenScrollEvent)

    window.addEventListener("resize", this.updateDimensions);
    // this.props.TopItemsList();
    const script = document.createElement("script");

    script.src = "https://code.iconify.design/2/2.2.1/iconify.min.js";
    script.async = true;

    document.body.appendChild(script);
  }
  componentDidUpdate(prevProps: any, nextProps: any) {
    if (nextProps.storeOpenFlag !== this.props.storeOpenFlag) {
      this.setState({ storeOpenFlag: this.props.storeOpenFlag })
      return true;
    }

  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
    window.removeEventListener("scroll", this.listenScrollEvent);
  }
  updateDimensions = () => {
    this.setState({ windowWidth: window.innerWidth });
  }

  handleCurtlery=(e:any)=>{
    this.props.handleCurtlery(e.target.checked)
    sessionStorage.setItem("curtlery",e.target.checked)
  }
  handleArrow = (id: any, index: any) => {
    // let data: any = sessionStorage.getItem("cart")
    let data: any = this.props.cart
    let cart: any = data ? JSON.parse(data) : null
    if (cart && cart.length > 0) {
      cart[index].is_open = !id
    }
    this.props.saveCart(cart)
    this.props.getCart()
    this.setState({ arrows: !this.state.arrows })
  }

  listenScrollEvent = (e: any) => {
    if (this.ScrollYLoc - 2 > window.scrollY) {
      this.scrollDownFlag = false;
    } else if (this.ScrollYLoc + 2 < window.scrollY) {
      this.scrollDownFlag = true
    }
    this.ScrollYLoc = window.scrollY;
  }

  incrementQuantity(currentIndex: any) {
    // let cart: any = sessionStorage.getItem("cart");
    let cart: any = this.props.cart
    let Cart: any = cart;
    let dataCart = JSON.parse(Cart);
    if (dataCart[currentIndex].item) {
      // if (dataCart[currentIndex].quantity < dataCart[currentIndex].selectedsize.max_quantity) {
      let price = (dataCart[currentIndex].selectedsize.discount_price || dataCart[currentIndex].selectedsize.discount_price == 0) ? dataCart[currentIndex].selectedsize.discount_price : dataCart[currentIndex].selectedsize.mrp;
      let actualPrice = dataCart[currentIndex].actualPrice;
      let extra_price = 0;
      // console.log("incrementing cart quantity")
      Object.keys(dataCart[currentIndex].item.modifiers).map((key: any) => {
        dataCart[currentIndex].item.modifiers[key].map((choice: any) => {
          if (choice.selected == true && choice.quantity > 0 && choice.modifier_sale_price > 0) {
            extra_price += choice.modifier_sale_price * choice.quantity;
          }
        })
      })
      dataCart.map((obj: any, index: any) => {
        if (currentIndex == index) {
          obj.totalItemPrice = parseFloat(obj.totalItemPrice) + parseFloat(price) + extra_price;
          obj.subTotal += actualPrice;
          obj.quantity += 1;
        } else {
          //dataCart.splice(currentIndex, 1);
          this.props.saveCart(dataCart);
          this.props.getCart();
          this.cartTotal = 0;
        }
      });
      this.props.saveCart(dataCart);
      setTimeout(() => {
        this.props.getCart();
      }, 500);
      // } 
      // else {
      //   toast.warn(`Maximum quantity selected for ${dataCart[currentIndex].selectedsize.size}`, { position: toast.POSITION.TOP_CENTER, hideProgressBar: true, autoClose: 3000 });
      // }
    }
    else {
      let price = dataCart[currentIndex].combo.discount_price ? Math.round(dataCart[currentIndex].combo.discount_price) : dataCart[currentIndex].combo.combo_mrp_price;
      let extra_price = 0
      let actualPrice = dataCart[currentIndex].actualPrice;
      let comboDetails = dataCart[currentIndex].combo;
      Object.keys(comboDetails.choices).map((key: any) => {
        comboDetails.choices[key].map((choice: any) => {
          if (choice.selected == true && choice.quantity > 0 && choice.extra_price > 0) {
            extra_price += choice.extra_price * choice.quantity;
          }
        })
      })

      dataCart.map((obj: any, index: any) => {
        if (currentIndex == index) {
          obj.totalItemPrice = obj.totalItemPrice + price + extra_price;
          obj.subTotal += actualPrice;
          obj.quantity += 1;
        } else {
          //dataCart.splice(currentIndex, 1);
          this.props.saveCart(dataCart);
          this.props.getCart();
          this.cartTotal = 0;
        }
      });
      this.props.saveCart(dataCart);
      setTimeout(() => {
        this.props.getCart();
      }, 500);
    }
  }

  decrementQuantity(item: any, currentIndex: any) {
    // let cart: any = sessionStorage.getItem("cart");
    let cart:any = this.props.cart
    let Cart: any = cart;
    let dataCart = JSON.parse(Cart);
    let products: any = [];
    let MapObj: any = {};
    //dataCart.forEach((cartItem:any) => {
    if (item.item) {
      MapObj = {
        quantity: 1,
        price: item.price,
        id: item.item.menu_item_id,
        name: item.item.item_name,
      }
    }
    else if (item.combo) {
      MapObj = {
        quantity: 1,
        price: item.price,
        id: item.combo.combo_id,
        name: item.combo.combo_name
      }
    }
    products.push(MapObj)
    // })
    // console.log(products, "products for datalayer");
    let windowObj = (window as any)
    windowObj.dataLayer.push({ ecommerce: null });
    windowObj.dataLayer.push({
      'event': 'removeFromCart',
      'ecommerce': {
        'currencyCode': 'AED',
        'remove': {                       // 'add' actionFieldObject measures.
          'products': products
        }
      }
    })
    if (dataCart[currentIndex].item) {
      let price = (dataCart[currentIndex].selectedsize.discount_price || dataCart[currentIndex].selectedsize.discount_price == 0) ? dataCart[currentIndex].selectedsize.discount_price : dataCart[currentIndex].selectedsize.mrp;
      let actualPrice = dataCart[currentIndex].actualPrice;
      let extra_price = 0;
      Object.keys(dataCart[currentIndex].item.modifiers).map((key: any) => {
        dataCart[currentIndex].item.modifiers[key].map((choice: any) => {
          if (choice.selected == true && choice.quantity > 0 && choice.modifier_sale_price > 0) {
            extra_price += choice.modifier_sale_price * choice.quantity;
          }
        })
      })
      dataCart.map((obj: any, index: any) => {
        if (currentIndex == index) {
          if (obj.quantity == 1) {
            dataCart.splice(currentIndex, 1);
            this.props.saveCart(dataCart);
            this.props.getCart();
            this.cartTotal = 0;
            this.props.TopItemsList(this.props.selectStoreId)
          }
          obj.totalItemPrice = obj.totalItemPrice - price - extra_price;
          obj.subTotal -= actualPrice;
          obj.quantity -= 1;
        }
      });
      this.props.saveCart(dataCart);
      setTimeout(() => {
        this.props.getCart();
      }, 500);
    }
    else {
      let price = dataCart[currentIndex].combo.discount_price ? dataCart[currentIndex].combo.discount_price : dataCart[currentIndex].combo.combo_mrp_price;
      let extra_price = 0
      let actualPrice = dataCart[currentIndex].actualPrice;
      let comboDetails = dataCart[currentIndex].combo;
      Object.keys(comboDetails.choices).map((key: any) => {
        comboDetails.choices[key].map((choice: any) => {
          if (choice.selected == true && choice.quantity > 0 && choice.extra_price > 0) {
            extra_price += choice.extra_price * choice.quantity;
          }
        })
      })
      dataCart.map((obj: any, index: any) => {
        if (currentIndex == index) {
          if (obj.quantity == 1) {
            dataCart.splice(currentIndex, 1);
            this.props.saveCart(dataCart);
            this.props.getCart();
            this.cartTotal = 0;
          }
          obj.totalItemPrice = obj.totalItemPrice - price - extra_price;
          obj.subTotal -= actualPrice;
          obj.quantity -= 1;
        }
      });
      this.props.saveCart(dataCart);
      setTimeout(() => {
        this.props.getCart();
      }, 500);
    }

  }
  goToCombo(item: any) {
    let comboName = item.combo_name.replace(/\s+/g, '-');
    this.props.history.push(`/combo/${item.combo_id}/${comboName}`)
  }

  async goToItem(item: any) {
    let storeSelected: any = sessionStorage.getItem("selectedStore");
    let store_id = storeSelected ? JSON.parse(storeSelected).store_id : null;
    this.props.getItemDetail(store_id, item.menu_item_id)
    await this.setState({ itemSelected: item, isOpen: true })
    // var scrollDiv = (document as any).getElementById("topItemRef").offsetTop;
    // window.scrollTo({ top: scrollDiv - 150, behavior: 'smooth' });
    // let element, height: any;
    // setTimeout(() => {
    //   element = (document as any).getElementById('mainDescDiv');
    //   if (element) {
    //     height = element.offsetHeight;
    //   }
    //   this.setState({ descBoxHeight: height })
    // }, 500)
    // this.props.history.push(`/item/${item.menu_item_id}/${itemName}`)
  }

  // changeQuantity = (carts: any, currentIndex: any) => {
  //   let cart: any = sessionStorage.getItem("cart");
  //   console.log("jvjbjhbjhbjhbjh")
  //   let Cart: any = cart;
  //   let dataCart = JSON.parse(Cart);
  //   let price = dataCart[currentIndex].price;
  //   let actualPrice = dataCart[currentIndex].actualPrice;
  //   dataCart.map((obj: any, index: any) => {
  //     if (currentIndex == index) {
  //       if (carts) {
  //         if(carts > obj.quantity){
  //         obj.totalItemPrice = obj.totalItemPrice + price;
  //         obj.subTotal = carts * actualPrice;
  //         obj.quantity = carts;
  //       } else {
  //         obj.totalItemPrice = obj.totalItemPrice - price;
  //         obj.subTotal = carts * actualPrice;
  //         obj.quantity = carts;
  //       } 
  //       } else {
  //         dataCart.splice(currentIndex, 1);
  //         this.props.saveCart(dataCart);
  //         this.props.getCart();
  //         this.cartTotal = 0;
  //       }
  //     }
  //   });
  //   this.props.saveCart(dataCart);
  //   setTimeout(() => {
  //     this.props.getCart();
  //   }, 500);
  // };

  calcItemTotal = (carts: any) => {
    let sum = 0;
    // cart.forEach((item: any) => {
    sum += parseInt(carts.totalItemPrice);
    // });
    return sum;
  };

  calcTotal = (data: any) => {
    let { deliveryfee } = this.props;
    let sum = 0;
    data.forEach((item: any) => {
      sum += parseFloat(item.totalItemPrice);
    });
    let discountValue: any = 0;
    // if (!_.isEmpty(coupon)) {
    // this.discount = coupon.coupon_value;
    // discountValue = (sum * coupon.coupon_value) / 100;
    // } else {
    // this.discount = 0;
    // this.cartTotal = 0;
    // }
    if (this.props.orderType == "Delivery") {
      sum = sum - discountValue;
    } else if (this.props.orderType == "Pickup") {
      sum = sum - discountValue;
    }
    this.cartTotal = sum;
    return sum;
  };

  goToCheckout = async () => {
    let data: any = this.props.cart;
    const token = await localStorage.getItem('token');
    let cart: any = JSON.parse(data);
    let products: any = []
    let MapObj: any = {}
    cart.forEach((cartItem: any) => {
      if (cartItem.item) {
        MapObj = {
          quantity: cartItem.quantity,
          price: cartItem.totalItemPrice,
          id: cartItem.item.menu_item_id,
          name: cartItem.item.item_name,
          variant: cartItem.selectedsize.size
        }
      }
      else if (cartItem.combo) {
        MapObj = {
          quantity: cartItem.quantity,
          price: cartItem.totalItemPrice,
          id: cartItem.combo.combo_id,
          name: cartItem.combo.combo_name
        }
      }
      products.push(MapObj)
    })

    let windowObj = (window as any)
    windowObj.dataLayer.push({ ecommerce: null });
    windowObj.dataLayer.push({
      'event': 'checkout',
      'ecommerce': {
        'currencyCode': 'AED',
        'checkout': {
          'actionField': { 'step': 'Location', 'option': 'Cash' },
          'products': products
        }
      }
    });
    if (token) {
      this.props.history.push({ pathname: '/checkout/location', search: window.location.search })
      this.props.setTabName("location")
    } else {
      this.props.history.push({ pathname: '/checkout/contact', search: window.location.search })
      this.props.setTabName("contact")
    }
  }

  calculateOrderDiscount = (data: any) => {
    let discount = 0;
    data.forEach((item: any) => {
      if (item.combo) {
        if (item.combo.discount_price) {
          discount += item.quantity * item.combo.combo_mrp_price - item.combo.discount_price;
        }
      } else {
        if (item.selectedsize.discount_price || item.selectedsize.discount_price == 0) {
          discount += item.quantity * (item.selectedsize.mrp - item.selectedsize.discount_price);
        }
      }
    })
    return discount;
  }

  productItem = (data: any) => {
    let lang = localStorage.getItem("lang")
    if (!_.isEmpty(data)) {
      let cartData: any[] = JSON.parse(data);
      if (cartData.length > 0) {
        return (
          <div className="col-lg-4 mob-view-padding">
            <div className="cart-summary">
              <h3>{i18next.t('cartPage.special_intruction')}</h3>
              <input
                type='text'
                defaultValue={this.props.special_ins}
                className="form-control special-ins"
                placeholder={lang=='en' ? "Anything we need to know?" : 'أي شيء نريد أن نعرفه؟'}
                name="special_instructions"
                onChange={this.props.handleSpecialInstruction}
              />
              <div style={{display:"-webkit-box"}}>
              <input className='mr-3' onChange={this.handleCurtlery} type='checkbox' />
              <p style={{marginRight:lang=="ar"?"5px":"0px"}} >{lang=="en"? "Add Cutlery":"إضافة أدوات"}</p>
              </div>
            </div>
            <div className="cart-summary">
              <h3>{i18next.t('cartPage.order_summary')}</h3>
              {/* <h4>
                <a
                  href="#total-estimate-section"
                  className="collapsed"
                  role="button"
                  aria-expanded="false"
                  aria-controls="total-estimate-section"
                  style={{ color: "#c00a27" }}
                >
                  {cartData.length} Producs in cart
                </a>
              </h4> */}
              {/* <div id="total-estimate-section">
                <table className="table table-mini-cart">
                  <tbody>
                    {cartData.map((carts, indexes) => (
                      <tr>
                        <td className="product-col">
                          <figure className="product-image-container">
                            <a href="product.html" className="product-image">
                              <img
                                // src={`${BASE_URL}${carts.image}`}
                                src="assets/images/c1.jpg"
                                alt="product"
                              />
                            </a>
                          </figure>
                          <div>
                            <h2
                              className="product-title"
                              style={{ color: "#c00a27" }}
                            >
                              <a>
                                {(!_.isEmpty(carts.item) &&
                                  carts.item.item_name) ||
                                  (!_.isEmpty(carts.combo) &&
                                    carts.combo.combo_name)}
                              </a>
                            </h2>

                            <span
                              className="product-qty"
                              style={{ width: "45%" }}
                            >
                              <NumericInput
                                min={0}
                                max={100}
                                mobile
                                className="form-control"
                                value={carts.quantity}
                                onChange={(value) =>
                                  this.changeQuantity(value, indexes)
                                }
                              />
                            </span>
                          </div>
                        </td>
                        <td className="price-col">
                          {this.calcItemTotal(carts)} PKR
                        </td> */}
              {/* <td>
                          <a href="#" title="Edit" style={{ color: "#c00a27" }}>
                            <i className="fa fa-trash" aria-hidden="true"></i>
                          </a>
                        </td> */}
              {/* </tr>
                    ))}
                  </tbody>
                </table>
              </div> */}
              {/* cart items end */}

              {/* coupan end */}
              {/* {cartData.map((carts, indexes) => ( */}
              <table className="table table-totals">
                <tbody>
                  {/* <tr>
                    <td>Subtotal</td>
                    <td>{this.calcTotal(cartData)} PKR</td>
                  </tr> */}
                  {/* <tr>
                      <td>Tax</td>
                      <td>15%</td>
                    </tr> */}
                </tbody>
                <tfoot>
                  <tr>
                    <td>{i18next.t('cartPage.order_total')}</td>
                    <td className="">{priceunit + this.calcTotal(cartData).toFixed(2)}</td>
                  </tr>
                  {this.calculateOrderDiscount(cartData) > 0 &&
                    <tr className="no-padding">
                      <td className="discountStyle">{i18next.t('common.your_discount')}</td>
                      <td className="discountStyle ">{priceunit + this.calculateOrderDiscount(cartData).toFixed(2)}</td>
                    </tr>
                  }
                </tfoot>
              </table>

              <div onClick={this.goToCheckout} className="checkout-methods">
                <div
                  className="btn btn-block btn-sm btn-primary custom-default-red-button p-3"
                >
                  {i18next.t('cartPage.order_now')}
                </div>
                {/* <a href="#" className="btn btn-link btn-block">Check Out with Multiple Addresses</a> */}
              </div>
            </div>
          </div>
          
        );
      }
    }
  };

  cartItem = (data: any) => {
    let lang = localStorage.getItem("lang")
    let { cart,topItems } = this.props
    let cartNew: any[] = [];
    let datanew: any = cart;
    if (!_.isEmpty(datanew)) {
      cartNew = JSON.parse(data);
    }
    let arr:any = []
    if (topItems && topItems.length > 0) {
      if (topItems.length > 0) {
        if (cartNew.length > 0) {
          arr=[]
          // console.log("arr",arr)
          cartNew.forEach((element:any) => {
            // topItems.forEach(function (obj: any, index: any) {
              if (element.item) {
                topItems = topItems.filter((obj:any)=>{
                  return obj.menu_item_id != element.item.menu_item_id
                })
              }
            // });
          })
        }
      }
    }
    if (!_.isEmpty(data)) {
      let cart: any[] = JSON.parse(data);
      if (cart.length > 0) {
        return (
          <div className="container">
            <div className="d row">
              <div className="col-lg-8">
                <div className="cart-summary web-view web-view-cart" style={{ padding: "1.4rem 1.8rem 4.8rem",height:cart.length<3?'unset':'' }}>
                  {/* <Scrollbars style={{ height: 400 }} > */}
                  <table className="table table-cart">
                    <thead>
                      <tr className="d-flex">
                        <th className="product-col col-12" style={{ padding: "0.4rem 1.5rem 1.2rem" }}>{i18next.t('cartPage.items_from_your_cart')}</th>

                        {/* <th className="product-col col-5">{i18next.t('cartPage.product')}</th> */}
                        {/* <th className="col-4">Description</th> */}
                        {/* <th className="price-col col-2">{i18next.t('cartPage.price')}</th> */}
                        {/* <th className="qty-col col-5">{i18next.t('cartPage.quantity')}</th> */}
                      </tr>
                    </thead>
                    <tbody>
                      <div className="web-view">
                        {cart.map((carts: any, indexes) => (
                          <div style={{ display: 'contents' }}>
                            <tr className="product-row d-flex">
                              <td className="product-col pl-4 col-md-5">

                                <h5 className="cart-title mb-0" style={{ fontSize: "20px" }}>
                                  {(!_.isEmpty(carts.item) &&
                                    lang =='ar' && carts.item.name_ar ? carts.item.name_ar : carts.item.item_name
                                    ) 
                                    ||
                                    (!_.isEmpty(carts.combo) &&
                                      carts.combo.combo_name)}
                                  {/* {carts.selectedsize &&
                                    `(${carts.selectedsize.size})`} */}
                                </h5>

                                {
                                  !_.isEmpty(carts.item) && typeof carts.item.modifiers == "string" ?
                                    JSON.parse(carts.item.modifiers).map((item: any) => {
                                      return (
                                        `${lang == 'ar' ? item.modifier_name_ar ? item.modifier_name_ar :item.modifier_name:item.modifier_name}, `
                                      )
                                    }) 
                                  :
                                  null
                                }
                                {
                                  (!_.isEmpty(carts.item) && !_.isEmpty(carts.item.modifiers)) ?
                                    <div>
                                      {
                                        <div onClick={() => this.handleArrow(carts.is_open, indexes)} className=' mt-1 d-flex col-5 justify-content-around btn btn-block btn-sm btn-primary custom-default-red-button'>
                                          <b className="sub-total mt-low-halla">
                                            {lang=="en"?"Details":"تفاصيل"}
                                          </b>                                              
                                          <div>
                                            {
                                              carts.is_open ?
                                              <a className="fa fa-angle-up collapsed " data-toggle="collapse" href="#collapseExample1" role="tablist" aria-expanded="false" aria-controls="collapseExample1" style={{ fontSize: "30px", color: "white", paddingTop: '5px' }}></a> :
                                              <a className="fa fa-angle-down collapsed" data-toggle="collapse" href="#collapseExample1" role="tablist" aria-expanded="false" aria-controls="collapseExample1" style={{ fontSize: "30px", color: "white", paddingTop: "5px" }}></a>
                                            }
                                          </div>
                                        </div>
                                      }
                                    </div>
                                  :
                                  null
                                }
                              </td>
                              {/* <td className="col">
                              <h4
                                className="product-title text-right"
                                style={{ color: "#c00a27" }}
                              >
                               
                                <br />
                              </h4>
                            </td> */}
                              {carts.selectedsize
                                ?
                                <td className="col-md-2">
                                  {((carts.selectedsize.discount_price || carts.selectedsize.discount_price == 0) && carts.selectedsize.order_channel == 'online') ?
                                    <>
                                      {priceunit + (carts.selectedsize.discount_price * carts.quantity).toFixed(2)}
                                    </>
                                    :
                                    <>
                                      {priceunit + (carts.totalItemPrice).toFixed(2)}
                                    </>
                                  }
                                </td>
                                :
                                <td className="col-md-2">
                                  {priceunit + (carts.price * carts.quantity).toFixed(2)}
                                </td>
                              }
                              <td className="col-md-3">
                                <div style={{ display: "flex", justifyContent: "space-evenly" }}>
                                  {/* <div className="decrement-btn" onClick={() => this.decrementQuantity(carts, indexes)}><a className="dec-icon">-</a></div> */}
                                  <div className="" onClick={() => this.decrementQuantity(carts, indexes)}><a >
                                    {/* <svg data-v-19f24e1b="" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" fill="currentColor" data-spec="icon-minus" className="w-5 h-5 ml-2" style={{ width: '16px', height: '16px', paddingLeft: '4px' }}><path fill="currentColor" d="M376 232H8c-4.42 0-8 3.58-8 8v32c0 4.42 3.58 8 8 8h368c4.42 0 8-3.58 8-8v-32c0-4.42-3.58-8-8-8z"></path></svg> */}
                                    <img style={{ width: "37px", cursor: "pointer" }} src="assets/images/minus-circle.png" />

                                  </a></div>
                                  <div style={{ fontWeight: "bold", fontSize: 24, display: "flex", alignItems: "center" }}>{carts.quantity}</div>
                                  {/* <div className="increment-btn custom-default-background-color" onClick={() => this.incrementQuantity(indexes)}><a className="inc-icon">+</a></div> */}
                                  <div className="" onClick={() => this.incrementQuantity(indexes)}><a >
                                    {/* <svg data-v-19f24e1b="" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" fill="currentColor" data-spec="icon-plus" className="w-5 h-5 ml-2" style={{ width: '16px', height: '16px', paddingLeft: '4px' }}><path fill="currentColor" d="M376 232H216V72c0-4.42-3.58-8-8-8h-32c-4.42 0-8 3.58-8 8v160H8c-4.42 0-8 3.58-8 8v32c0 4.42 3.58 8 8 8h160v160c0 4.42 3.58 8 8 8h32c4.42 0 8-3.58 8-8V280h160c4.42 0 8-3.58 8-8v-32c0-4.42-3.58-8-8-8z"></path></svg> */}
                                    <img style={{ width: "37px", cursor: "pointer" }} src="assets/images/plus-circle.png" />

                                  </a></div>
                                </div>
                                {/* <NumericInput
                                min={0}
                                max={100}
                                size={10}
                                //type="number"
                                //readOnly 
                                //disabled  
                                mobile
                                className="form-control numericInputCart"
                                value={carts.quantity}
                                onChange={(value) =>
                                this.changeQuantity(value, indexes)
                                }
                              /> */}
                              </td>
                              <td className="product-col col-md-5">
                                <figure className="product-image-container">
                                  <a className="product-image">
                                    <img
                                      style={{ maxWidth: '8rem' }}
                                      src={`${BASE_URL}${carts.selectedsize ? carts.selectedsize.image_url : carts.image}`}
                                      alt={carts.selectedsize ? carts.selectedsize.alt_text : "cart product"}
                                    />
                                  </a>
                                </figure>
                              </td>
                            </tr>
                            {carts.is_open &&
                              <div className="d-flex details-tab-content" id={`${carts.menu_item_id}`}>
                                {/* <p style={{ fontFamily: "Open Sans" }}> */}
                                <ul className="d-flex flex-column" style={{ width: '55%' }}>
                                  {/* {
                                    (!_.isEmpty(carts.item) && lang=='ar' ? carts.item.name_ar ? carts.item.name_ar :  carts.item.item_name:carts.item.item_name)
                                  }   */}
                                  {
                                    !_.isEmpty(carts.item) && typeof carts.item.modifiers == "string" ?
                                      JSON.parse(carts.item.modifiers).map((item: any) => {
                                        return (
                                          `${lang == 'ar' ? item.modifier_name_ar ? item.modifier_name_ar :item.modifier_name:item.modifier_name}, `
                                        )
                                      }) 
                                    :
                                      (!_.isEmpty(carts.item) && !_.isEmpty(carts.item.modifiers)) &&
                                      Object.keys(carts.item.modifiers).map(
                                        (key: any, index) => {
                                          return (
                                            carts.item.modifiers[key].map(
                                              (item: any, index: any) => {
                                                return (
                                                  item.selected === true &&
                                                    <li style={{ fontWeight: 'bold', textAlign: 'start', marginBottom: '10px' }}>
                                                      <div className="d-flex justify-content-between">
                                                        <span>
                                                          {`${lang=='ar' ? item.modifier_name_ar ? item.modifier_name_ar : item.modifier_name:item.modifier_name}`}
                                                        </span>
                                                        <span>
                                                          {`${lang=='ar' ? item.modifier_name_ar ? item.modifier_name_ar : item.modifier_sale_price:item.modifier_sale_price } x ${item.quantity} = ${(item.quantity*item.modifier_sale_price).toFixed(2)} AED`}
                                                        </span>
                                                      </div>
                                                    </li>
                                                )
                                              })
                                          );
                                        }
                                      )
                                  }
                                </ul>
                                {/* </p> */}
                                {
                                  !_.isEmpty(carts.combo) && typeof carts.combo.modifiers == "string" ?
                                    JSON.parse(carts.combo.modifiers).map((item: any) => {
                                      return (
                                        `${item.modifier_name} * ${item.quantity}, `
                                      )
                                    }) :
                                    (!_.isEmpty(carts.combo) && !_.isEmpty(carts.combo.modifiers)) &&
                                    // itemData === null && undefined &&
                                    Object.keys(carts.combo.modifiers).map(
                                      (key: any, index) => {
                                        return (
                                          carts.combo.modifiers[key].map(
                                            (item: any, index: any) => {
                                              return (
                                                item.selected === true &&
                                                // item.modifier_name + ' x ' + item.quantity + " , "
                                                <p style={{ fontFamily: "Open Sans" }}>


                                                  {
                                                    item.modifier_name + ' x ' + item.quantity + ', '
                                                  }

                                                  {/* <div className="">
                                                {priceunit + item.modifier_sale_price * item.quantity}{''}
                                              </div> */}


                                                </p>
                                              )
                                            })
                                        );
                                      }
                                    )
                                }

                                {
                                  !_.isEmpty(carts.combo) && typeof carts.combo.modifiers == "string" ?
                                    JSON.parse(carts.combo.modifiers).map((item: any) => {
                                      return (
                                        `${item.modifier_name} * ${item.quantity}, `
                                      )
                                    }) :
                                    (!_.isEmpty(carts.combo) && !_.isEmpty(carts.combo.choices)) &&
                                    // itemData === null && undefined &&
                                    Object.keys(carts.combo.choices).map(
                                      (key: any, index) => {
                                        return (
                                          carts.combo.choices[key].map(
                                            (item: any, index: any) => {
                                              return (
                                                item.selected === true && item.quantity > 0 &&
                                                // item.modifier_name + ' x ' + item.quantity + " , "
                                                <p style={{ fontFamily: "Open Sans" }} >


                                                  {item.size ?
                                                    <span>{item.item_name}({JSON.parse(item.size).size}) x {item.quantity * carts.quantity}</span>
                                                    :
                                                    <span>{item.item_name + ' x ' + item.quantity}</span>
                                                  }
                                                  {item.extra_price > 0 && item.quantity == 1 ?
                                                    <span>
                                                      {priceunit + item.extra_price * carts.quantity}{''}
                                                    </span>
                                                    :
                                                    <span>
                                                      {priceunit + item.extra_price * item.quantity * carts.quantity}{''}
                                                    </span>
                                                  }
                                                  <div>
                                                  </div>

                                                </p>
                                              )
                                            })
                                        );
                                      }
                                    )
                                }

                                {/* {_.isEmpty(carts.item.modifiers) ? <p style={{ fontFamily: "Open Sans" }}> 
                                {(!_.isEmpty(carts.item) &&
                                  carts.item.item_name)}</p> : ""

                                } */}


                              </div>
                            }
                            {/* <tr className="">
                              <tr className="d-flex">
                                <td style={{ fontWeight: 'bold' }} className="col-4">
                                  {
                                    `${i18next.t('cartPage.total')}`
                                  }
                                </td>
                                <td style={{ fontWeight: 'bold' }} className="col-4">
                                  {priceunit + (carts.totalItemPrice).toFixed(2)}{''}
                                </td>
                                <td className="col-4">
                                </td>
                              </tr>
                            </tr> */}

                          </div>
                        ))}
                        {this.calculateOrderDiscount(cart) > 0 &&
                          <tr className="product-row d-flex">
                            {/* <tr className="d-flex"> */}
                              <td style={{ fontWeight: 'bold', textAlign: 'start' }} className="col-4 discountStyle">
                                {
                                  `${i18next.t('common.your_discount')}`
                                }
                              </td>
                              <td style={{ fontWeight: 'bold' }} className="col-4 discountStyle">
                                {priceunit + (this.calculateOrderDiscount(cart)).toFixed(2)}
                              </td>
                              <td className="col-4">
                              </td>
                            {/* </tr> */}
                          </tr>
                        }
                      </div>

                    </tbody>
                    {/* <tfoot>
                        <tr>
                          <td colSpan={4} className="clearfix">
                            <div className="float-left">
                              <a
                                href="category.html"
                                className="btn btn-outline-secondary"
                              >
                                Continue Shopping
                              </a>
                            </div>

                            <div className="float-right">
                              <a
                                href="#"
                                className="btn btn-outline-secondary btn-clear-cart"
                              >
                                Clear Shopping Cart
                              </a>
                              <a
                                href="#"
                                className="btn btn-outline-secondary btn-update-cart"
                              >
                                Update Shopping Cart
                              </a>
                            </div>
                          </td>
                        </tr>
                      </tfoot> */}
                  </table>

                  {/* </Scrollbars> */}
         
                </div>
               <div className="web-top-items">
                {topItems && topItems.length > 0 &&
          <section style={{paddingTop:"0px"}} className="pb-4">
            <div className="container">
              <h2 className="section-title" style={{ textTransform: 'none' }}>{i18next.t('cartPage.you_may_also_like')}</h2>
              <div className="d-flex" id="scrollbar" style={{ marginTop: '',width:"99%", overflow:"auto", }}>
                {
                  topItems.map((item: any, index: any) => (
                    <div className="col-4 col-sm-4 col-md-2" key={index}>
                      <div className="cartItemTitle product-default inner-quickview inner-icon">
                        {(item.menu_item_id && item.menu_item_id !== "") ?
                          <div onClick={() => this.goToItem(item)}>
                            <img style={{ borderRadius: 5 }} src={`${BASE_URL}${JSON.parse(item.item_size)[0].image_url}`} alt={JSON.parse(item.item_size)[0].alt_text || 'item image'} />
                          </div>
                          :
                          <div onClick={() => this.goToCombo(item)}>
                            <img style={{ borderRadius: 5 }} src={`${BASE_URL}${item.image_url}`} alt={item.alt_text || 'combo image'} />
                          </div>
                        }
                        <div className="mrtop">
                          <h2 className="product-title">
                          <a>{lang == 'ar' && item.name_ar ? item.name_ar : item.item_name }</a>
                          </h2>
                          {item.item_size &&
                            JSON.parse(item.item_size)[0].discount_price && (JSON.parse(item.item_size)[0].order_channel == 'online') && (
                              <div className="price-box">
                                <span className="product-price">
                                  {priceunit + Math.round(JSON.parse(item.item_size)[0].discount_price)}
                                </span>

                                <span style={{
                                  textDecoration: "line-through",
                                  fontSize: "1.5rem",
                                  color: "rgb(192 10 39)",
                                  paddingLeft: "1rem"
                                }}
                                  className="product-price">
                                  {priceunit + JSON.parse(item.item_size)[0].mrp
                                  }
                                </span>

                              </div>
                            )}
                          <div className="hert">
                            {item.item_size &&
                              JSON.parse(item.item_size)[0].discount_price == null && (
                                <div style={{ marginBottom: 'unset' }} className="price-box">
                                  <span className="product-price">

                                    {priceunit + JSON.parse(item.item_size)[0].mrp}
                                  </span>
                                </div>
                              )
                            }
                            <div style={{ width: 'unset' }} className="category-wrap">
                              <div className="category-list">
                                <a className="product-category"></a>
                              </div>
                              {/* {
                                item.isFav === true ? <i className="icon-heart-1 custom-default-fonts-color"></i> : <a style={{ cursor: "pointer" }}
                                  onClick={() => this.addFavouriteItem(item.menu_item_id, true)}
                                ><i className="icon-heart" style={{position: "absolute",
                                  cursor: "pointer",
                                  left: "77%",
                                  top: "63%"}}></i></a>
                              } */}
                            </div>
                          </div>
                        </div>
                        <figure id="figureSize" style={{ paddingTop: '2em' }}>
                          {(item.menu_item_id && item.menu_item_id !== "") ?
                            <p onClick={() => this.goToItem(item)} className="btn-quickview" title={i18next.t('common.select')}>{i18next.t('common.select')}</p>
                            :
                            <p onClick={() => this.goToCombo(item)} className="btn-quickview" title={i18next.t('common.select')}>{i18next.t('common.select')}</p>
                          }
                        </figure>

                      </div>
                    </div>
                  ))
                }
              </div>
              <div id={`topItemRef`}></div>
              <BsModal backdrop="static" show={this.state.isOpen}>
                  <BsModal.Body>
                    <InnerDetails
                      storeOpenFlag={this.state.storeOpenFlag}
                      closeDetails={this.closeDetails}
                      item={this.state.itemSelected}
                    />
                  </BsModal.Body>
                </BsModal>
            </div>
          </section>

        }
        </div>

              </div>
              <p className="pl-4 mob-view pt-3 text-center" style={{ color: "#FF8300", fontSize: "18px", fontWeight: "bold", fontFamily: "Open Sans" }}>{i18next.t('cartPage.items_from_your_cart')}</p>

              <div className="mob-view web-view-cart bg-white" style={{height:cart.length<3?'unset':''}} >
               
                {cart.map((carts: any, indexes) => (
                  <div className="pb-4" style={{ display: 'grid', borderBottom: "1px solid #ddd", padding: "15px" }}>

                    <div className="row " style={{ display: "block" }}>

                      <div className="d-flex">
                        <div className="col-7 pt-2">


                          <h5 className="cart-title" style={{ fontSize: "14px" }}>
                            {/* {carts.quantity}{"X. "} */}
                            {(!_.isEmpty(carts.item) &&
                              // carts.item.item_name
                              lang =='ar' && carts.item.name_ar ? carts.item.name_ar : carts.item.item_name
                              ) ||
                              (!_.isEmpty(carts.combo) &&
                                carts.combo.combo_name)}
                            {/* {carts.selectedsize &&
                              `(${carts.selectedsize.size})`} */}
                              </h5>
                          {carts.selectedsize
                            ?
                            <div className="" style={{
                              fontFamily: 'Open Sans',
                              fontWeight: "bolder",
                              color: "#8a2432"
                            }}>
                              {((carts.selectedsize.discount_price || carts.selectedsize.discount_price == 0) && carts.selectedsize.order_channel == 'online') ?
                                <>
                                  {priceunit + (carts.selectedsize.discount_price * carts.quantity).toFixed(2)}
                                </>
                                :
                                <>
                                  {priceunit + (carts.selectedsize.mrp * carts.quantity).toFixed(2)}
                                </>
                              }
                            </div>
                            :
                            <div className="" style={{
                              fontFamily: 'Open Sans',
                              fontWeight: "bolder",
                              color: "#8a2432"
                            }}>
                              {priceunit + (carts.price * carts.quantity).toFixed(2)}
                            </div>
                          }

                        </div>
                        <div className="col-2">

                        </div>
                        <div className="col-4">
                          <figure className="product-image-container">
                            <a className="product-image">
                              <img
                                className=""
                                style={{ maxWidth: "10rem" }}
                                src={`${BASE_URL}${carts.selectedsize ? carts.selectedsize.image_url : carts.image}`}
                                // src="assets/images/c4.jpg"
                                alt={carts.selectedsize ? carts.selectedsize.alt_text : "cart product"}
                              />
                            </a>
                          </figure>


                        </div>
                      </div>


                      <div className="mt-3 d-flex align-items-center">

                        <div onClick={() => this.handleArrow(carts.is_open, indexes)} className='p-0 col-4 d-flex justify-content-around btn btn-block btn-sm btn-primary custom-default-red-button'>

                          <b className="sub-total mt-low-halla">
                            {lang=="en"?"Details":"تفاصيل"}
                          </b>

                          <div>

                            {carts.is_open ?

                              <a className="fa fa-angle-up collapsed " data-toggle="collapse" href="#collapseExample1" role="tablist" aria-expanded="false" aria-controls="collapseExample1" style={{ fontSize: "30px", color: "white", paddingTop: '5px' }}></a> :

                              <a className="fa fa-angle-down collapsed" data-toggle="collapse" href="#collapseExample1" role="tablist" aria-expanded="false" aria-controls="collapseExample1" style={{ fontSize: "30px", color: "white", paddingTop: "5px" }}></a>
                            }
                          </div>
                          {/* <a className="sub-total collapsed acordeon-toggle" data-toggle="collapse" href="#collapseExample1" role="tablist" aria-expanded="false" aria-controls="collapseExample1"></a> */}
                        </div>
                        <div className="col-4"></div>
                        <div className="col-5">
                          <div className='buttons-margin' style={{ display: "flex", justifyContent: "space-evenly" }}>
                            {/* <div className="decrement-btn" onClick={() => this.decrementQuantity(carts, indexes)}><a className="dec-icon">-</a></div> */}
                            <div className="" onClick={() => this.decrementQuantity(carts, indexes)}><a >
                              {/* <svg data-v-19f24e1b="" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" fill="currentColor" data-spec="icon-minus" className="w-5 h-5 ml-2" style={{ width: '16px', height: '16px', paddingLeft: '4px' }}><path fill="currentColor" d="M376 232H8c-4.42 0-8 3.58-8 8v32c0 4.42 3.58 8 8 8h368c4.42 0 8-3.58 8-8v-32c0-4.42-3.58-8-8-8z"></path></svg> */}
                              <img style={{ width: "37px", cursor: "pointer" }} src="assets/images/minus-circle.png" />

                            </a></div>
                            <div style={{ fontWeight: "bold", fontSize: 24, display: "flex", alignItems: "center", color: "black" }}>{carts.quantity}</div>
                            {/* <div className="increment-btn custom-default-background-color" onClick={() => this.incrementQuantity(indexes)}><a className="inc-icon">+</a></div> */}
                            <div className="" onClick={() => this.incrementQuantity(indexes)}><a >
                              {/* <svg data-v-19f24e1b="" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" fill="currentColor" data-spec="icon-plus" className="w-5 h-5 ml-2" style={{ width: '16px', height: '16px', paddingLeft: '4px' }}><path fill="currentColor" d="M376 232H216V72c0-4.42-3.58-8-8-8h-32c-4.42 0-8 3.58-8 8v160H8c-4.42 0-8 3.58-8 8v32c0 4.42 3.58 8 8 8h160v160c0 4.42 3.58 8 8 8h32c4.42 0 8-3.58 8-8V280h160c4.42 0 8-3.58 8-8v-32c0-4.42-3.58-8-8-8z"></path></svg> */}
                              <img style={{ width: "37px", cursor: "pointer" }} src="assets/images/plus-circle.png" />

                            </a></div>
                          </div>

                        </div>
                      </div>
                    </div>
                    {carts.is_open &&
                      <div className="d-flex" id={`${carts.menu_item_id}`} style={{
                        background: "beige",
                        color: "black",
                        fontSize: "14px",
                        paddingTop: "12px",
                      }}>
                        <p style={{ fontFamily: "Open Sans" }}>
                        {/* {(!_.isEmpty(carts.item) &&
                             carts.item.item_name)} */}
                          {
                            !_.isEmpty(carts.item) && 
                            (lang == 'ar' && carts.item.name_ar ? carts.item.name_ar :carts.item.item_name)
                          }
                          {
                            !_.isEmpty(carts.item) && typeof carts.item.modifiers == "string" ?
                              JSON.parse(carts.item.modifiers).map((item: any) => {
                                return (
                                  `${lang == 'ar' ? item.modifier_name_ar ? item.modifier_name_ar :item.modifier_name:item.modifier_name}`
                                )
                              }) :
                              (!_.isEmpty(carts.item) && !_.isEmpty(carts.item.modifiers)) &&
                              // itemData === null && undefined &&
                              Object.keys(carts.item.modifiers).map(
                                (key: any, index) => {
                                  return (
                                    carts.item.modifiers[key].map(
                                      (item: any, index: any) => {
                                        return (
                                          item.selected === true &&
                                          <>
                                            
                                            

                                          {", "}{lang=='ar' ? item.modifier_name_ar ? item.modifier_name_ar : item.modifier_name : item.modifier_name}
                                          </>
                                        )
                                      })
                                  );
                                }
                              )
                          }
                        </p>
                        {
                          !_.isEmpty(carts.combo) && typeof carts.combo.modifiers == "string" ?
                            JSON.parse(carts.combo.modifiers).map((item: any) => {
                              return (
                                `${item.modifier_name} * ${item.quantity}, `
                              )
                            }) :
                            (!_.isEmpty(carts.combo) && !_.isEmpty(carts.combo.modifiers)) &&
                            // itemData === null && undefined &&
                            Object.keys(carts.combo.modifiers).map(
                              (key: any, index) => {
                                return (
                                  carts.combo.modifiers[key].map(
                                    (item: any, index: any) => {
                                      return (
                                        item.selected === true &&
                                        // item.modifier_name + ' x ' + item.quantity + " , "
                                        <p style={{ fontFamily: "Open Sans", display: "flex" }}>
                                          {(!_.isEmpty(carts.item) &&
                                            carts.item.item_name) ||
                                            (!_.isEmpty(carts.combo) &&
                                              carts.combo.combo_name)}
                                          +

                                          {
                                            item.modifier_name + ' x ' + item.quantity + ', '
                                          }

                                          {/* <div className="">
                                                {priceunit + item.modifier_sale_price * item.quantity}{''}
                                              </div> */}


                                        </p>
                                      )
                                    })
                                );
                              }
                            )
                        }

                        {
                          !_.isEmpty(carts.combo) && typeof carts.combo.modifiers == "string" ?
                            JSON.parse(carts.combo.modifiers).map((item: any) => {
                              return (
                                `${item.modifier_name} * ${item.quantity}, `
                              )
                            }) :
                            (!_.isEmpty(carts.combo) && !_.isEmpty(carts.combo.choices)) &&
                            // itemData === null && undefined &&
                            Object.keys(carts.combo.choices).map(
                              (key: any, index) => {
                                return (
                                  carts.combo.choices[key].map(
                                    (item: any, index: any) => {
                                      return (
                                        item.selected === true && item.quantity > 0 &&
                                        // item.modifier_name + ' x ' + item.quantity + " , "
                                        <p style={{ fontFamily: "Open Sans" }} >


                                          {item.size ?
                                            <span>{item.item_name}({JSON.parse(item.size).size}) x {item.quantity * carts.quantity}</span>
                                            :
                                            <span>{item.item_name + ' x ' + item.quantity}</span>
                                          }


                                          {item.extra_price > 0 && item.quantity == 1 ?
                                            <span>
                                              {priceunit + item.extra_price * carts.quantity}{''}
                                            </span>
                                            :
                                            <span>
                                              {priceunit + item.extra_price * item.quantity * carts.quantity}{''}
                                            </span>
                                          }

                                          <div>
                                          </div>

                                        </p>
                                      )
                                    })
                                );
                              }
                            )
                        }


                      </div>
                    }
                    {/* <tr className="">
                              <tr className="d-flex">
                                <td style={{ fontWeight: 'bold' }} className="col-4">
                                  {
                                    `${i18next.t('cartPage.total')}`
                                  }
                                </td>
                                <td style={{ fontWeight: 'bold' }} className="col-4">
                                  {priceunit + (carts.totalItemPrice).toFixed(2)}{''}
                                </td>
                                <td className="col-4">
                                </td>
                              </tr>
                            </tr> */}

                  </div>
                ))}
                {this.calculateOrderDiscount(cart) > 0 &&
                  <tr className="product-row">
                    <tr className="d-flex">
                      <td style={{ fontWeight: 'bold' }} className="col-4 discountStyle">
                        {
                          `${i18next.t('common.your_discount')}`
                        }
                      </td>
                      <td style={{ fontWeight: 'bold' }} className="col-4 discountStyle">
                        {priceunit + (this.calculateOrderDiscount(cart)).toFixed(2)}
                      </td>
                      <td className="col-4">
                      </td>
                    </tr>
                  </tr>
                }
         
              </div>
            
              {this.productItem(data)}
            
             
            </div>
          </div >
        );
      } else {
        return (
          <div className="container">
            <div className="row">
              <div className="col-lg-6 mx-5 text-center">
                <Link to={`/menu${window.location.search}`}>
                  <div className="cart-summary">
                      <div className="cart-summary-text" style={{ color: '#000000' }}>{i18next.t('common.add_items_to_cart')}</div>
                  </div>
                </Link>
              </div>
              <div className="web-top-items">
              {topItems && topItems.length > 0 && cart.length === 0 &&
          <section className="pb-4">
            <div className="container">
              <h2 className="section-title" style={{ textTransform: 'none' }}>{i18next.t('cartPage.you_may_also_like')}</h2>
              <div className="d-flex" id="scrollbar" style={{ marginTop: '',width:"99%", overflow:"auto", }}>
                {
                  topItems.map((item: any, index: any) => (
                    <div className="col-6 col-sm-6 col-md-2" key={index}>
                      <div className="cartItemTitle product-default inner-quickview inner-icon">
                        {(item.menu_item_id && item.menu_item_id !== "") ?
                          <div onClick={() => this.goToItem(item)}>
                            <img style={{ borderRadius: 5 }} src={`${BASE_URL}${JSON.parse(item.item_size)[0].image_url}`} alt={JSON.parse(item.item_size)[0].alt_text || 'item image'} />
                          </div>
                          :
                          <div onClick={() => this.goToCombo(item)}>
                            <img style={{ borderRadius: 5 }} src={`${BASE_URL}${item.image_url}`} alt={item.alt_text || 'combo image'} />
                          </div>
                        }
                        <div className="mrtop">
                          <h2 className="product-title">
                          <a>{lang == 'ar' && item.name_ar ? item.name_ar : item.item_name }</a>
                          </h2>
                          {item.item_size &&
                            JSON.parse(item.item_size)[0].discount_price && (JSON.parse(item.item_size)[0].order_channel == 'online') && (
                              <div className="price-box">
                                <span className="product-price">
                                  {priceunit + Math.round(JSON.parse(item.item_size)[0].discount_price)}
                                </span>

                                <span style={{
                                  textDecoration: "line-through",
                                  fontSize: "1.5rem",
                                  color: "rgb(192 10 39)",
                                  paddingLeft: "1rem"
                                }}
                                  className="product-price">
                                  {priceunit + JSON.parse(item.item_size)[0].mrp
                                  }
                                </span>

                              </div>
                            )}
                          <div className="hert">
                            {item.item_size &&
                              JSON.parse(item.item_size)[0].discount_price == null && (
                                <div style={{ marginBottom: 'unset' }} className="price-box">
                                  <span className="product-price">

                                    {priceunit + JSON.parse(item.item_size)[0].mrp}
                                  </span>
                                </div>
                              )
                            }
                            <div style={{ width: 'unset' }} className="category-wrap">
                              <div className="category-list">
                                <a className="product-category"></a>
                              </div>
                              {/* {
                                item.isFav === true ? <i className="icon-heart-1 custom-default-fonts-color"></i> : <a style={{ cursor: "pointer" }}
                                  onClick={() => this.addFavouriteItem(item.menu_item_id, true)}
                                ><i className="icon-heart" style={{position: "absolute",
                                  cursor: "pointer",
                                  left: "77%",
                                  top: "63%"}}></i></a>
                              } */}
                            </div>
                          </div>
                        </div>
                        <figure id="figureSize" style={{ paddingTop: '2em' }}>
                          {(item.menu_item_id && item.menu_item_id !== "") ?
                            <p onClick={() => this.goToItem(item)} className="btn-quickview" title={i18next.t('common.select')}>{i18next.t('common.select')}</p>
                            :
                            <p onClick={() => this.goToCombo(item)} className="btn-quickview" title={i18next.t('common.select')}>{i18next.t('common.select')}</p>
                          }
                        </figure>

                      </div>
                    </div>
                  ))
                }
              </div>
              <div id={`topItemRef`}></div>
              <BsModal backdrop="static" show={this.state.isOpen}>
                  <BsModal.Body>
                    <InnerDetails
                      storeOpenFlag={this.state.storeOpenFlag}
                      closeDetails={this.closeDetails}
                      item={this.state.itemSelected}
                    />
                  </BsModal.Body>
                </BsModal>
            </div>
          </section>

        }
        </div>
            </div>
          </div>

        );
      }
    } else {
      return (
        <div className="container">
          <div className="row">
            <div className="col-lg-6 mx-5 text-center">
            <Link to={`/menu${window.location.search}`}>
              <div className="cart-summary">
                  <div className="cart-summary-text" style={{ color: '#000000' }}>{i18next.t('common.add_items_to_cart')}</div>
              </div>
              </Link>
            </div>
            <div className="web-top-items">
            {topItems && topItems.length > 0 && cart.length === 0 &&
          <section className="pb-4">
            <div className="container">
              <h2 className="section-title" style={{ textTransform: 'none' }}>{i18next.t('cartPage.you_may_also_like')}</h2>
              <div className="d-flex" id="scrollbar" style={{ marginTop: '',width:"99%", overflow:"auto", }}>
                {
                  topItems.map((item: any, index: any) => (
                    <div className="col-6 col-sm-6 col-md-2" key={index}>
                      <div className="cartItemTitle product-default inner-quickview inner-icon">
                        {(item.menu_item_id && item.menu_item_id !== "") ?
                          <div onClick={() => this.goToItem(item)}>
                            <img style={{ borderRadius: 5 }} src={`${BASE_URL}${JSON.parse(item.item_size)[0].image_url}`} alt={JSON.parse(item.item_size)[0].alt_text || 'item image'} />
                          </div>
                          :
                          <div onClick={() => this.goToCombo(item)}>
                            <img style={{ borderRadius: 5 }} src={`${BASE_URL}${item.image_url}`} alt={item.alt_text || 'combo image'} />
                          </div>
                        }
                        <div className="mrtop">
                          <h2 className="product-title">
                          <a>{lang == 'ar' && item.name_ar ? item.name_ar : item.item_name }</a>
                            {/* <a >{item.item_name ? item.item_name : item.combo_name}</a> */}
                          </h2>
                          {item.item_size &&
                            JSON.parse(item.item_size)[0].discount_price && (JSON.parse(item.item_size)[0].order_channel == 'online') && (
                              <div className="price-box">
                                <span className="product-price">
                                  {priceunit + Math.round(JSON.parse(item.item_size)[0].discount_price)}
                                </span>

                                <span style={{
                                  textDecoration: "line-through",
                                  fontSize: "1.5rem",
                                  color: "rgb(192 10 39)",
                                  paddingLeft: "1rem"
                                }}
                                  className="product-price">
                                  {priceunit + JSON.parse(item.item_size)[0].mrp
                                  }
                                </span>

                              </div>
                            )}
                          <div className="hert">
                            {item.item_size &&
                              JSON.parse(item.item_size)[0].discount_price == null && (
                                <div style={{ marginBottom: 'unset' }} className="price-box">
                                  <span className="product-price">

                                    {priceunit + JSON.parse(item.item_size)[0].mrp}
                                  </span>
                                </div>
                              )
                            }
                            <div style={{ width: 'unset' }} className="category-wrap">
                              <div className="category-list">
                                <a className="product-category"></a>
                              </div>
                              {/* {
                                item.isFav === true ? <i className="icon-heart-1 custom-default-fonts-color"></i> : <a style={{ cursor: "pointer" }}
                                  onClick={() => this.addFavouriteItem(item.menu_item_id, true)}
                                ><i className="icon-heart" style={{position: "absolute",
                                  cursor: "pointer",
                                  left: "77%",
                                  top: "63%"}}></i></a>
                              } */}
                            </div>
                          </div>
                        </div>
                        <figure id="figureSize" style={{ paddingTop: '2em' }}>
                          {(item.menu_item_id && item.menu_item_id !== "") ?
                            <p onClick={() => this.goToItem(item)} className="btn-quickview" title={i18next.t('common.select')}>{i18next.t('common.select')}</p>
                            :
                            <p onClick={() => this.goToCombo(item)} className="btn-quickview" title={i18next.t('common.select')}>{i18next.t('common.select')}</p>
                          }
                        </figure>

                      </div>
                    </div>
                  ))
                }
              </div>
              <div id={`topItemRef`}></div>
              <BsModal backdrop="static" show={this.state.isOpen}>
                  <BsModal.Body>
                    <InnerDetails
                      storeOpenFlag={this.state.storeOpenFlag}
                      closeDetails={this.closeDetails}
                      item={this.state.itemSelected}
                    />
                  </BsModal.Body>
                </BsModal>
            </div>
          </section>

        }
        </div>
          </div>
        </div>
      
      );
    }
  };
  handleAdd = async (item: any) => {
    let lang = localStorage.getItem("lang")

    let cart: any = [];
    this.props.getCart();
    let data: any = this.props.cart;
    if (!_.isEmpty(data)) {
      cart = JSON.parse(data);
    }
    // console.log("cart",cart)
    if (!_.isEmpty(item)) {
      if (item.menu_item_id) {
        // console.log(item.combo_id, item.menu_item_id)
        let sizearray: any = JSON.parse(item.item_size);
        //     //if Item is menu_item
        if (!_.isEmpty(cart)) {
          let found: any = '';
          cart.forEach((element: any) => {
            if (element['item']) {
              if (element['item'].menu_item_id == item.menu_item_id) {
                if (sizearray[0].size == element.selectedsize.size) {
                  // console.log("match",sizearray[0].size)
                  element.quantity = element.quantity + 1;
                  element.totalItemPrice = Math.round(element.price * element.quantity);
                  element.subTotal = Math.round(element.actualPrice * element.quantity);
                  element.is_open = false;
                  return (found = 1);
                }
              }
            }
          });
          if (found == '') {
            const itemcart = {
              item: item,
              quantity: 1,
              selectedsize: sizearray[0],
              price: (sizearray[0].discount_price || sizearray[0].discount_price == 0) ? Math.round(sizearray[0].discount_price) : Math.round(sizearray[0].mrp),
              totalItemPrice: ((sizearray[0].discount_price || sizearray[0].discount_price == 0) && (sizearray[0].order_channel == 'online')) ? Math.round(sizearray[0].discount_price) : Math.round(sizearray[0].mrp),
              actualPrice: Math.round(sizearray[0].price),
              subTotal: Math.round(sizearray[0].price),
              image: item.image_url !== "" ? item.image_url : '',
              is_open: false,
            };
            cart.push(itemcart);
          }
        } else {
          const itemcart = {
            item: item,
            quantity: 1,
            selectedsize: sizearray[0],
            price: (sizearray[0].discount_price || sizearray[0].discount_price == 0) ? Math.round(sizearray[0].discount_price) : Math.round(sizearray[0].mrp),
            totalItemPrice: ((sizearray[0].discount_price || sizearray[0].discount_price == 0) && (sizearray[0].order_channel == 'online')) ? Math.round(sizearray[0].discount_price) : Math.round(sizearray[0].mrp),
            actualPrice: Math.round(sizearray[0].price),
            subTotal: Math.round(sizearray[0].price),
            image: item.image_url !== "" ? item.image_url : ''
          };
          cart.push(itemcart);
        }
      } else if (item.combo_id > 0) {
        // console.log(item.combo_id)
        //     //if Item is combo item
        if (!_.isEmpty(cart)) {
          // console.log(cart)
          // console.log(item.combo_id, item.menu_item_id)
          let found: any = '';
          cart.forEach((element: any) => {
            // console.log(element['combo'].combo_id)
            if (element['combo']) {
              if (element['combo'].combo_id == item.combo_id) {
                element.quantity = element.quantity + 1;
                element.totalItemPrice = Math.round(element.price * element.quantity);
                element.subTotal = Math.round(element.actualPrice * element.quantity);
                return (found = 1);
              }
            }
          });
          // console.log(found)
          if (found == '') {
            const itemcart = {
              combo: item,
              quantity: 1,
              price: item.discount_price ? Math.round(item.discount_price) : Math.round(item.combo_mrp_price),
              totalItemPrice: (item.discount_price && (item.order_channel == 'online')) ? Math.round(item.discount_price) : Math.round(item.combo_mrp_price),
              actualPrice: Math.round(item.combo_sales_price),
              subTotal: Math.round(item.combo_sales_price),
              image: item.image_url !== "" ? item.image_url : ''
            };
            cart.push(itemcart);
          }
        } else {
          const itemcart = {
            combo: item,
            quantity: 1,
            price: item.discount_price ? Math.round(item.discount_price) : Math.round(item.combo_mrp_price),
            totalItemPrice: (item.discount_price && (item.order_channel == 'online')) ? Math.round(item.discount_price) : Math.round(item.combo_mrp_price),
            actualPrice: Math.round(item.combo_sales_price),
            subTotal: Math.round(item.combo_sales_price),
            image: item.image_url !== "" ? item.image_url : ''
          };
          cart.push(itemcart);
        }
      } else {
        // console.log('error');
      }
    }
    this.props.saveCart(cart);
    this.props.getCart();
    this.props.TopItemsList(this.props.selectStoreId)
    this.setState({ isOpen: false })
    toast.success(`${item.item_name && item.item_name || item.combo_name && item.combo_name} ${lang=="en"?"Added to cart":"تمت الإضافة إلى عربة التسوق"}`, { position: toast.POSITION.TOP_CENTER, hideProgressBar: true, autoClose: 3000,className: 'toast-message' })
  };

  addFavouriteItem = async (menu_item_id: any, topItem?: any) => {
    let { selectStoreId } = this.props;
    const token = await localStorage.getItem('token');
    if (token) {
      let param1: any = {};
      if (menu_item_id !== "") {
        param1 = {
          menu_item_id: menu_item_id,
          // group_id: group_id
        };
        if (topItem) {
          param1.topItem = true
        }
      }
      // console.log('combofav',param1)
      this.props.favouriteAdd(param1, selectStoreId);

    } else {
      toast.warning(i18next.t('errorMsg.login_for_fav'), { position: toast.POSITION.TOP_CENTER, hideProgressBar: true, autoClose: 3000,className: 'toast-message' })
    }
  };
  closeDetails = (groupIndex: any) => {
    this.setState({ descBoxHeight: 0, isOpen: false })
  }
  render() {
    let lang = localStorage.getItem("lang")
    // let maintoken = localStorage.getItem("mainToken")
    // if (!maintoken) {
    //   this.props.history.push("/")
    // }
    let { cart, topItems } = this.props;
    let cartNew: any[] = [];
    let data: any = this.props.cart;
    if (!_.isEmpty(data)) {
      cartNew = JSON.parse(data);
    }
    let arr:any = []
    if (topItems && topItems.length > 0) {
      if (topItems.length > 0) {
        if (cartNew.length > 0) {
          arr=[]
          // console.log("arr",arr)
          cartNew.forEach(element => {
            // topItems.forEach(function (obj: any, index: any) {
              if (element.item) {
                topItems = topItems.filter((obj:any)=>{
                  return obj.menu_item_id != element.item.menu_item_id
                })
              }
            // });
          })
        }
      }
    }

    return (
      <div>
        <UseTrackEvent location={window.location} setAddEvent={this.handleSetAddEvent} />
        <main className="main min-height">
          <div style={{position:"relative",zIndex:2}} className="container">
            <div className="backBtn-wrapper mobile-view-page-cart">
              <Link to={`/menu${window.location.search}`}>
                <div className="org-header__btn--circle">
                  {/* <svg data-v-c2f068f0="" fill="#820c24" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" data-spec="icon-arrow-left" className="org-header__icon" style={{width: '15px', height: '15px'}}><path d="M74.363 79.637a8.997 8.997 0 010 12.727C72.605 94.121 70.303 95 68 95s-4.605-.879-6.363-2.637l-36-36a8.997 8.997 0 010-12.727l36-36a8.997 8.997 0 0112.727 0 8.997 8.997 0 010 12.727L44.727 50l29.636 29.637z"></path></svg> */}
                  <span className="iconify"
                    style={{
                      // marginRight: "10px",
                      width: "45px",
                      height: "45px",
                      color: "#179145"
                    }}
                    data-icon="bi:arrow-left-circle">
                  </span>
                </div>
              </Link>
            </div>
            {this.cartItem(cart)}
          </div>
        </main>

        <div className="mobile-top-items">
        {topItems && topItems.length > 0 &&
          <section className="">
            <div className="container">
              <h2 className="section-title" style={{ textTransform: 'none' }}>{i18next.t('cartPage.you_may_also_like')}</h2>
              <div className="d-flex" id="scrollbar" style={{ marginTop: '1em',width:"99%", overflow:"auto", }}>
                {
                  topItems.map((item: any, index: any) => (
                    <div className="col-6 col-sm-6 col-md-2" key={index}>
                      <div className="cartItemTitle product-default inner-quickview inner-icon">
                        {(item.menu_item_id && item.menu_item_id !== "") ?
                          <div onClick={() => this.goToItem(item)}>
                            <img style={{ borderRadius: 5 }} src={`${BASE_URL}${JSON.parse(item.item_size)[0].image_url}`} alt={JSON.parse(item.item_size)[0].alt_text || 'item image'} />
                          </div>
                          :
                          <div onClick={() => this.goToCombo(item)}>
                            <img style={{ borderRadius: 5 }} src={`${BASE_URL}${item.image_url}`} alt={item.alt_text || 'combo image'} />
                          </div>
                        }
                        <div className="mrtop">
                          <h2 className="product-title">
                             <a>{lang == 'ar' && item.name_ar ? item.name_ar : item.item_name }</a>
                          </h2>
                          {item.item_size &&
                            JSON.parse(item.item_size)[0].discount_price && (JSON.parse(item.item_size)[0].order_channel == 'online') && (
                              <div className="price-box">
                                <span className="product-price">
                                  {priceunit + Math.round(JSON.parse(item.item_size)[0].discount_price)}
                                </span>

                                <span style={{
                                  textDecoration: "line-through",
                                  fontSize: "1.5rem",
                                  color: "rgb(192 10 39)",
                                  paddingLeft: "1rem"
                                }}
                                  className="product-price">
                                  {priceunit + JSON.parse(item.item_size)[0].mrp
                                  }
                                </span>

                              </div>
                            )}
                          <div className="hert">
                            {item.item_size &&
                              JSON.parse(item.item_size)[0].discount_price == null && (
                                <div style={{ marginBottom: 'unset' }} className="price-box">
                                  <span className="product-price">

                                    {priceunit + JSON.parse(item.item_size)[0].mrp}
                                  </span>
                                </div>
                              )
                            }
                            <div style={{ width: 'unset' }} className="category-wrap">
                              <div className="category-list">
                                <a className="product-category"></a>
                              </div>
                              {/* {
                                item.isFav === true ? <i className="icon-heart-1 custom-default-fonts-color"></i> : <a style={{ cursor: "pointer" }}
                                  onClick={() => this.addFavouriteItem(item.menu_item_id, true)}
                                ><i className="icon-heart"></i></a>
                              } */}
                            </div>
                          </div>
                        </div>
                        <figure id="figureSize" style={{ paddingTop: '2em' }}>
                          {(item.menu_item_id && item.menu_item_id !== "") ?
                            <p onClick={() => this.goToItem(item)} className="btn-quickview" title={i18next.t('common.select')}>{i18next.t('common.select')}</p>
                            :
                            <p onClick={() => this.goToCombo(item)} className="btn-quickview" title={i18next.t('common.select')}>{i18next.t('common.select')}</p>
                          }
                        </figure>

                      </div>
                    </div>
                  ))
                }
              </div>
              <div id={`topItemRef`}></div>
              <BsModal backdrop="static" show={this.state.isOpen}>
                  <BsModal.Body>
                    <InnerDetails
                      storeOpenFlag={this.state.storeOpenFlag}
                      closeDetails={this.closeDetails}
                      item={this.state.itemSelected}
                    />
                  </BsModal.Body>
                </BsModal>
            </div>
          </section>

        }
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    cart: state.login.cartData,
    orderType: state.login.orderType,
    deliveryfee: state.login.deliveryfee,
    GroupName: state.login.GroupName,
    stores: state.login.storesList,
    addressCurrent: state.login.addressCurrent,
    address_save: state.login.address_save,
    groupList: state.login.groupList,
    topItems: state.login.topItemsList,
    selectStoreId: state.login.selectStoreId,
    special_ins: state.login.special_instructions,
    storeOpenFlag: state.login.storeOpenFlag,
    curtlery:state.login.curtlery
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    // logoutCustomer: function () {
    //   dispatch(logoutCustomer());
    // },
    findLocalStores: function (lat: any, long: any) {
      dispatch(findLocalStores(lat, long));
    },
    findPickupStores: function (lat: any, long: any, value: any) {
      dispatch(findPickupStores(lat, long, value));
    },
    saveGuestAddress: function (address: any) {
      dispatch(saveGuestAddress(address));
    },
    getCart: function () {
      dispatch(getCart());
    },
    saveCart: function (cart: any[]) {
      dispatch(saveCart(cart));
    },
    // TopItemsList: function () {
    //   dispatch(TopItemsList());
    // },
    favouriteAdd: function (param1: any, store_id: any) {
      dispatch(favouriteAdd(param1, store_id));
    },
    setTabName: (tabName: any) => {
      dispatch(setTabName(tabName));
    },
    handleSpecialInstruction: (event: any) => {
      dispatch(handleSpecialInstruction(event));
    },
    handleCurtlery:(value:any) => {
      dispatch(handleCurtlery(value))
    },
    TopItemsList:(store_id:any)=>{
      dispatch(TopItemsList(store_id))
    },
    getItemDetail: (store_id: any, id: any) => {
      dispatch(getItemDetail(store_id, id))
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(index);
